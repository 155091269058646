.myproject__bg {
   background: #fff;
   box-shadow: 1px 0px 10px #b1b1b1;
   padding: 8px;
   .layer_1 {
        // box-shadow: 1px 0px 10px #b1b1b1;
        background-color:#f5df96;
        padding: 10px;
            .layer_2 {
                padding: 10px;
                .content {
                    h5 {
                        color: #f5df96;
                        font-family: monospace;
                        text-shadow: 2px 2px 0 #333;
                    }
                }
                .description {
                    padding-top: 1rem;
                    color: #333;
                    font-weight: 600;
                    font-family: monospace;
                    p {
                        font-size: 20px;
                        padding: 0;
                        a {
                            text-decoration: none;
                            color: #fff;
                            font-size: 3rem;
                            font-family: monospace;
                            text-shadow: 3px 3px 0 #333;
                            transition: all .2s linear;
                            display: block;
                     
                        }
                        a:hover{
                            transform: translateY(-3px);
                            text-shadow: 4px 4px 0 #333;
                        }
                    }
                }
            }
            }
}