.Home {
    font-family: serif;
    h1 {
        font-family: monospace;
        font-weight: 600;
        letter-spacing: 2px;
    }
    p {
        margin: 0;
        line-height: 1.4rem;
        color: #333;
        font-size: 1.2rem;
        padding-bottom: .2rem;
    }
    .avatar {
        box-shadow: 2px 0 10px rgb(161, 161, 161);
    }
    .btn {
        background-color: tomato !important;
        box-shadow: 2px 2px 0 #333;
        font-size: .8rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fff;
        font-family: monospace;
        font-weight: 600;
        transition: .15s linear;
    }   
    .btn:hover {
        transform: translateY(-3px);
        box-shadow: 4px 4px 0 #333;

    }
    .wrapper-down {
        position: relative;
            .download {
                position: absolute;
                background-color: white;
                right: 0;
                top: 160px;
                width: 300px;
                min-height: 400px;
                border: 3px solid #333;
                border-radius: 12px;
                transition: 1s ease-out;
                z-index: 1;
                h5 {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-family: monospace;
                    letter-spacing: 1px;
                    padding: 0;
                    margin-bottom: 20px;

                }
                p{
                    font-size: 20px;
                    line-height: 1.5rem;
                    color: rgb(160, 160, 160);
                    padding: 0;
                }
                .downed {
                    animation: opacity .5s linear;
                    font-size: 20px;
                }
            }
            .active {
                top: -260px;
            }

            span {
                width: 30px;
                height: 30px;
                display:flex;
                justify-content: center;
                align-items: center;
            }
            .bg-down {
                background-color: rgb(243, 210, 21) !important;
                border: 2px solid #000;
                font-weight: 600;
                letter-spacing: 2px;
                cursor: pointer;
                transition: all .1s linear;
            }
            .bg-down:hover {
                transform: translateY(-2px);
                box-shadow: 1px 1px 0 #000;
            }
            h3 {
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 600;
                font-family: monospace;
            }
            .down-CV {
              text-decoration: none;
              color: #000;  
            }
    }
    
}
