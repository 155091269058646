.box-gioi-thieu {
    box-shadow: 1px 0px 10px #b1b1b1;
    &__bg {
        .content {
            border: 2px solid #e1bf4d;
            a {
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                color: #333;
                font-size: 12px;
                text-shadow: 1px 1px #000;
            }
        }
        background-color: #f5df96;
        .my-name {
            color: #fff;
            font-size: 3rem;
            font-family: monospace;
            text-shadow: 2px 2px 0 #333;
        }
        .my-level {
            margin-top: 2px;
            border-top: 2px solid #000;
            border-bottom: 2px solid #000;
            font-size: 42px;
            letter-spacing: 4px;
            padding: 0px;
            font-weight: 600;
            font-family: monospace;
            text-align: justify;
        }
        .contact {
            color: rgb(248, 52, 17);
            font-size: 18px;
            font-weight: 600;
            font-family: monospace;
            transform: rotate(355deg);
        }
    }
}
.nav-bar {
    box-shadow: 1px 0px 10px #b1b1b1;
    &__item {
        font-size: 16px;
        font-family: monospace;
        letter-spacing: 2px;
        font-weight: bold;
        text-transform: uppercase;
        margin-right: 16px;
        text-decoration: none;
        color: #333;
        display: block;
        padding: 10px;
        border-radius: 4px;
        transition: all .2s ease-in-out;
        text-align: center;
    }
    &__item:hover,.active {
        background-color:  white;
        color:#e1bf4d;
    }
}