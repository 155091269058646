.About {
    font-family: monospace;
    .head {
        font-size: 46px;
        font-weight: 600;
        float: left;
        padding-right: 12px;
        line-height: 48px;
    }
    .title{
        font-family: system-ui;
        text-transform: uppercase;
        font-size: 30px;
        margin-bottom: 20px;
    }
    .paragrap {
        font-size: 18px;
        color: #676767;
        line-height: 1.5rem;
        text-align: justify;
        padding: 0;
        margin-bottom: 50px;
    }
    b {
        font-weight: bold;
        color: #000;
    }
    h4 {
        text-transform: uppercase;
        letter-spacing: 4px;
        text-shadow: 1px 1px #000;
        font-size: 20px;
    }
    h5 {
        font-family: monospace;
        color: #000;
        margin: 0;
        font-size: 16px;
        text-shadow: 1px 1px #000;
    }
    .answer {
        font-family: monospace;
        font-size: 16px;
        padding-left: 16px;
        margin-bottom: 10px;
    }
}